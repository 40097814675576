.contact{
    // box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    // border-top-right-radius: 10px;
    // border-bottom-right-radius: 10px;
    position: relative;
    // transform: translateY(100%);
    // opacity: 0;
    transition: 2s all ease;
    .header{
        text-align: center;
        margin-bottom: 20px;
    }
    .map-iframe{
        padding: 0;
        iframe{
            width: 100%;
            height: 450px;
        }
    }
    // .contact-form{
       
    //     padding: 20px;
    //     border-radius: 10px;
    //     border-top-right-radius: 0;
    //     border-bottom-right-radius: 0;
    //     border: 2px solid #cdcdcd;
    //     .error-msg{
    //         margin: 0;
    //         color: rgb(226, 77, 77);
    //         font-size: 12px;
    //     }
    //     .btn{
    //         background-color: #3b4878;
    //         border-color: #3b4878;
    //     }
    //     @media screen and (max-width: 420px) {
    //         border: none;
    //         width: 90%;
    //         margin: auto;
    //        border-radius: 0;

    //  }
    // }
    .contact-information{
        background-color: #212529;
        color: #fff;
        padding: 20px;
        position: sticky;
        bottom: 0;
        // border-radius: 10px;
        // border-top-left-radius: 0;
        // border-bottom-left-radius: 0;
        .cursor-pointer{
            cursor: pointer;
        }
        img{
            width: 20px;
            height: 20px;
            margin-right: 10px;
        }
    }

}