.home{
    width: 100%;
    background-color: #212529;
    display: flex;
    flex-wrap: wrap;
    color: #fff;
    .left-panel{
        flex: 1;
        padding: 50px;
        justify-content: center;
        display: flex;
        flex-flow: column;
        .heading{
            h1{
                margin-top: 70px;
                font-size: 70px;
            }
            h5{
                margin-bottom: 30px;
            }
           
        }
        p{
            line-height: 1.875em;
        }
    }
    .right-panel{
        flex: 1;
        padding: 50px;
        position: relative;
        display: flex;
        justify-content: center;
        .image-wrapper{
            left: 20px;
            width: 435px;
            // position: absolute;
            top: 20px;
            // height: 940px;
            background-color: #fff;
            img{
                width: 435px;
                object-fit: cover;
                position: relative;
                top: -20px;
                left: -20px;
            }
        }
        @media screen and (max-width: 420px) {
                display: none;
        }
    }
}