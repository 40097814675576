.ayu-rashmi-app{
    // height: 100%;
    display: flex;
    flex-flow: column;
    align-items: stretch;
    width: 100%;
    .main{
        flex: 1;
        background: #212529;
        color: #fff;
        .home .services .contact{
            height: inherit;
        }
    }
    // for toast messages 
    .Toastify__toast--error{
        background-color: var(--toastify-color-progress-error);
    }
    .Toastify__toast--success{
        background-color: var(--toastify-color-progress-success);
    }
    .Toastify__toast-body{
        color: var(--toastify-color-light);
        svg{
            fill: var(--toastify-color-light);
        }
        .Toastify__close-button{
            svg{
            fill: var(--toastify-color-light);
        }
        }
    }
}