.navbar{
    .container-fluid{
        .navbar-brand{
            display: flex;
            flex-wrap: nowrap !important;
            .header-banner-logo{
                font-size: 12px;
                display: flex;
                align-items: center;
                img{
                    border-radius: 50%;
                }
                .header-text{
                    display: flex;
                    flex-flow: column;
                    margin-left: 10px;
                    white-space: pre-wrap;
                    text-align: left;
                    p{
                        margin: 0;
                    }
                }
            }
        }
    }
   
}