.services{
    padding: 80px;
    .top-section{
        h1{
            text-align: center;
            margin: 10px 0;
        }
        p{
            text-align: center;
        }
    }
    .service-card{
        // background: #fff;
        // color: #000;
        // border-radius: 10px;
        display: inline-block;
        min-height: 300px;
        margin: 1rem;
        position: relative;
        min-width: 300px;
        // box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
        transition: all 0.3s cubic-bezier(.25,.8,.25,1);
        padding: 12px;
        border: 1px solid rgba(255, 255, 255, .25);
        border-radius: 20px;
        background-color: #47474700;
        box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.25);

        backdrop-filter: blur(15px);
        &:hover {
            box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
          }
          .service-name{
            text-transform: capitalize;
          }
        .image-container{
            margin: 0 -12px;
            img{
                width: 100%;
                height: 300px;
            }
        }
    }
}