.about{
.header{
    text-align: center;
}
    .about-founders{
        .about-doctor{
            display: flex;
            text-align: center;
            align-items: center;
            flex-wrap: wrap;
            img{
                width: 400px;
                height: 400px;
                border-radius: 10px;
                @media screen and (max-width: 420px) {
                    width: 100%;
            }
            }
            .doctor-description{
                font-size: 16px;
                white-space: pre-wrap;
                padding: 0 50px;
                line-height: 2;
                font-weight: 500;
                @media screen and (max-width: 420px) {
                    padding: 0 20px;
            }
            }
        }
    }
}
